var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-page"},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-4"},[_vm._l((_vm.menu.filter((item) => !item.hide)),function(item){return [(item.children)?_c('div',{key:item.text},[_c('div',{class:[
              'menu-item',
              'semi-bold',
              {
                orcid: item.text === 'Author profile',
              },
              {
                selected: item.showChildren,
              },
            ],attrs:{"id":item.id},on:{"click":function($event){$event.preventDefault();item.showChildren = !item.showChildren}}},[_vm._v("\n            "+_vm._s(item.text)+"   "),_c('i',{class:[
                'fa',
                item.showChildren ? 'fa-caret-down' : 'fa-caret-right',
              ]})]),(item.showChildren)?_c('div',{staticClass:"children"},_vm._l((item.children.filter((child) => !child.hide)),function(child){return _c('a',{key:child.text,attrs:{"id":child.id,"href":child.link}},[_c('div',{class:['child', { selected: child.selected }]},[_vm._v("\n                - "+_vm._s(child.text)+"\n              ")])])}),0):_vm._e()]):_c('a',{key:item.text,attrs:{"id":item.id,"href":item.link}},[_c('div',{class:[
              'menu-item',
              'semi-bold',
              {
                plus: item.text === 'Submit manuscript',
              },
              {
                selected: item.selected,
              },
            ]},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])])]})],2),_c('div',{staticClass:"col-12"},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }