<script>
import { readAccount } from '@/api'

// hybrid design is used for now
export default {
  data() {
    return {
      account: {},
      // links can be internal routes in future
      menu: [
        {
          id: 'left-menu--saved-searches',
          text: 'My saved alerts',
          link: '/accounts/SavedAlerts',
          selected: false,
        },
        {
          id: 'left-menu--author-profile',
          text: 'Author profile',
          icon: 'sign-in-orcid.png',
          children: [
            {
              id: 'left-menu--view-profile',
              text: 'View',
              link: '/accounts/myPublications',
              selected: false,
            },
            {
              id: 'left-menu--claim-articles',
              text: 'Claim articles',
              link: '/accounts/articleClaiming',
              selected: false,
            },
          ],
          hide: false,
          showChildren: false,
        },
        {
          id: 'left-menu--submit-manuscript',
          text: 'Submit manuscript',
          icon: 'sign-in-plus.png',
          link: '/accounts/SubmitManuscript',
          hide: false,
          selected: false,
        },
        {
          id: 'left-menu--manage-account',
          text: 'Manage account',
          children: [
            {
              id: 'left-menu--connect-account',
              text: 'Connect accounts',
              link: '/accounts/ConnectAccounts',
              selected: false,
            },
            {
              id: 'left-menu--change-password',
              text: 'Change password',
              link: '/accounts/ChangePassword',
              hide: false,
              selected: false,
            },
            {
              id: 'left-menu--change-display-name',
              text: 'Change display name',
              link: '/accounts/ChangeDisplayName',
              selected: false,
            },
            {
              id: 'left-menu--change-alerts-email',
              text: 'Change alerts email',
              link: '/accounts/ChangeAlertsEmail',
              selected: false,
            },
            {
              id: 'left-menu--delete-account',
              text: 'Delete account',
              link: '/accounts/DeleteAccount',
              selected: false,
            },
          ],
          showChildren: false,
        },
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to) {
        const { menu } = this
        const { path } = to
        for (let i = 0; i < menu.length; i++) {
          const item = menu[i]
          if (item.children) {
            const children = item.children
            for (let i = 0; i < children.length; i++) {
              const child = children[i]
              if (path.includes(child.link)) {
                child.selected = true
                item.showChildren = true
                break
              }
            }
          } else if (path.includes(item.link)) {
            item.selected = true
            break
          }
        }
      },
    },
  },
  async created() {
    const response = await readAccount()
    if (!response['Error']) {
      this.account = response
      const sources = response.credentials.map(
        (credential) => credential.source
      )
      const { menu } = this
      menu[1].hide = !sources.includes('ORCID')
      menu[2].hide = !sources.includes('PLUS_WT')
      menu[3].children[1].hide = !sources.includes('EUROPEPMC')
    }
  },
}
</script>
<template>
  <div class="account-page">
    <div class="grid-row">
      <div class="col-4">
        <template v-for="item in menu.filter((item) => !item.hide)">
          <div v-if="item.children" :key="item.text">
            <div
              :id="item.id"
              :class="[
                'menu-item',
                'semi-bold',
                {
                  orcid: item.text === 'Author profile',
                },
                {
                  selected: item.showChildren,
                },
              ]"
              @click.prevent="item.showChildren = !item.showChildren"
            >
              {{ item.text }}&nbsp;&nbsp;&nbsp;<i
                :class="[
                  'fa',
                  item.showChildren ? 'fa-caret-down' : 'fa-caret-right',
                ]"
              />
            </div>
            <div v-if="item.showChildren" class="children">
              <a
                v-for="child in item.children.filter((child) => !child.hide)"
                :id="child.id"
                :key="child.text"
                :href="child.link"
              >
                <div :class="['child', { selected: child.selected }]">
                  - {{ child.text }}
                </div>
              </a>
            </div>
          </div>
          <a v-else :id="item.id" :key="item.text" :href="item.link">
            <div
              :class="[
                'menu-item',
                'semi-bold',
                {
                  plus: item.text === 'Submit manuscript',
                },
                {
                  selected: item.selected,
                },
              ]"
            >
              {{ item.text }}
            </div>
          </a>
        </template>
      </div>
      <div class="col-12">
        <router-view />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.account-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  a {
    text-decoration: none;
  }
  .menu-item {
    box-sizing: border-box;
    width: $base-unit * 55;
    @media only screen and (max-width: $breakpoint-extra-small) {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
    max-width: 100%;
    border-top: thin solid #fff;
    border-bottom: thin solid #fff;
    background-color: #f2f2f2;
    padding: ($base-unit * 3.75) 0 ($base-unit * 3.75) ($base-unit * 10);
    cursor: pointer;
    color: $epmc-darker-blue;
    &.orcid {
      background: #f2f2f2 url('~@/assets/sign-in-orcid.png') no-repeat
        ($base-unit * 2.25);
    }
    &.plus {
      background: #f2f2f2 url('~@/assets/sign-in-plus.png') no-repeat
        ($base-unit * 2.25);
    }
    &.selected {
      background-color: #d8d8d8;
      color: $epmc-darker-grey;
    }
    &:hover {
      background-color: $epmc-darker-blue;
      color: $epmc-white;
    }
  }
  .children {
    width: $base-unit * 55;
    @media only screen and (max-width: $breakpoint-extra-small) {
      width: 100%;
      text-align: center;
    }
    max-width: 100%;
    background-color: #f2f2f2;
    padding: ($base-unit * 2) 0;
    .child {
      padding: ($base-unit * 0.75) 0 ($base-unit * 0.75) ($base-unit * 11.25);
      @media only screen and (max-width: $breakpoint-extra-small) {
        padding-left: 0;
      }
      color: $epmc-darker-blue;
      &.selected {
        background-color: #d8d8d8;
        color: $epmc-darker-grey;
      }
      &:hover {
        background-color: $epmc-darker-blue;
        color: $epmc-white;
      }
    }
  }
}
</style>
